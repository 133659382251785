import React from 'react';

const CategoryCard = ({ imageUrl }) => {
  return (
    <div className="w-16 h-16 bg-gradient-to-b from-orange-300 to-primary text-white rounded-lg shadow-lg flex items-center justify-center">
      <img className="w-8 object-cover h-8" src={imageUrl} />
      </div>
  );
};

export default CategoryCard;
