import React from "react";

const Admin = () => {

    return (
        <div className="fixed flex flex-col justify-center items-center w-full h-screen">
                <h1>Disi admine</h1>
        </div>
    );
}

export default Admin;