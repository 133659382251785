import React from 'react';
import CategoryCard from './category_card';

const HorizontalScroll = () => {
  const items = [
    {
      id: 1,
      name: 'Delicious Pizza',
      price: '12.99',
      imageUrl : "/apple.svg"
    },
    {
      id: 2,
      name: 'Tasty Burger',
      price: '9.99',
      imageUrl : "/burger.svg"
    },
    {
      id: 3,
      name: 'Pasta Bowl',
      price: '10.99',
      imageUrl : "/pizza.svg"
    },
    {
      id: 4,
      name: 'Healthy Salad',
      price: '8.99',
      imageUrl : "/cake.svg"
    },
    {
      id: 5,
      name: 'Cupcake Delight',
      price: '4.99',
      imageUrl : "/apple.svg"
    },
  ];

  return (
    <div className="whitespace-nowrap scrollbar-hidden overflow-auto h-20 text-center">
      <div className="whitespace-nowrap inline-block scrollbar-hidden">
        {items.map(item => (
          <div key={item.id} className="inline-block h-5 pr-2">
            <CategoryCard className="h-5"
              imageUrl={item.imageUrl}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default HorizontalScroll;
