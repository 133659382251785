// BottomSheet.js
import React, { useState, useEffect } from 'react';
import { FaX } from 'react-icons/fa6';

function BottomSheet({ isOpen, onClose, food }) {
  const [visible, setVisible] = useState(isOpen);

  useEffect(() => {
    if (isOpen) {
      setVisible(true);
    } else {
      setTimeout(() => setVisible(false), 400); 
    }
  }, [isOpen]);

  if (!visible) return null;

  return (
    <div className="fixed inset-0 flex items-end bg-black bg-opacity-50 z-50 transition-all duration-500">
      <div
        className={`bg-white w-full rounded-t-3xl p-6 shadow-lg transform ${
          isOpen ? 'animate-slide-up' : 'animate-slide-down'
        }`}
      >
        <button className="text-gray-500 self-end" onClick={onClose}>
          <FaX></FaX>
        </button>
        <div className="mt-4">
          <h2 className="text-2xl font-semibold">{food?.name || 'Loading...'}</h2>
          <p className="text-lg font-medium text-gray-700">
            ${food?.price || '0.00'}
          </p>
          {food?.imgs[0] && (
            <img
              src={`https://letmenu.com/images/products/${food._id}_${food.imgs[0]}`}
              alt={food.name}
              className="w-full h-48 object-cover rounded-lg my-4"
            />
          )}
          <button className='bg-primary p-4 text-white w-full rounded-md'>Dodaj prozivod</button>
        </div>
      </div>
    </div>
  );
}

export default BottomSheet;
