import React, { useState, useEffect } from 'react';
import SplashScreen from '../components/splash';
import FoodCard from '../components/food_card';
import HorizontalScroll from '../components/scrollable_widget';
import GlobalApi from '../manager/GlobalApi';
import { FaShoppingCart } from 'react-icons/fa';
import BottomSheet from '../components/bottom_sheet';
import { useParams } from 'react-router-dom';

function App() {
  const [loading, setLoading] = useState(false);
  const [showSplash, setShowSplash] = useState(true);
  const [selectedFood, setSelectedFood] = useState();
  const [categories, setCategoriesNames] = useState([]);
  const [isOpened, setIsOpened] = useState(false);
  const [isSplashVisible, setIsSplashVisible] = useState(true);
  const [isSplashFading, setIsSplashFading] = useState(false); // State to control fade-out effect
  const [animateText, setAnimateText] = useState(true);

  const handleSplashFinish = () => {
    setIsSplashVisible(false);
  };


  const { menuId, tableId } = useParams();

  const handleFoodClick = (food) => {
    setSelectedFood(food);
    setIsOpened(true);
  };

  const closeBottomSheet = () => {
    setIsOpened(false);
  };

  const getMenus = () => {
    GlobalApi.getMenus().then(res => {
      const categoryNames = res.data.category_ids.map(category => ({
        id: category._id,
        name: category.name,
        products: category.product_ids || []
      }));
      setCategoriesNames(categoryNames);
    })

  }
  const listItems = categories.map((category) => (
    <div key={category.id}>
      <h1 className='py-6 text-white text-start font-poppins font-bold'>{category.name}</h1>
      {category.products.length > 0 ? (
        <div>
          {category.products.map((product) => (
            <div key={product._id} onClick={() => handleFoodClick(product)}>
              <FoodCard
                name={product.name}
                price={product.price}
                imageUrl={`https://letmenu.com/images/products/${product._id}_${product.imgs[0]}`}
              />
            </div>
          ))}
        </div>
      ) : (
        <p>Nema još dodanih proizvoda...</p>
      )}
    </div>

  ))
  useEffect(() => {
    getMenus();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsSplashFading(true); 
    }, 1000); 

    setTimeout(() => {
      setIsSplashVisible(false); 
    }, 2000);
  }, []);


  return (
    <div className="h-screen w-screen bg-white relative">
     <div
     className={`${isSplashVisible ? 'absolute' : 'hidden'} inset-0 bg-primary transition-opacity duration-1000 ease-in-out z-50 ${
       isSplashFading ? 'opacity-0' : 'opacity-100'
     }`}
   >
 </div>
 <div className='absolute z-100'>
 </div>
        <div>
          <div className='hidden sm:flex justify-center items-center h-screen'>
            <h1>Trenutno nije dostupna web verzija meni-a, probajte preko mobitela.</h1>
          </div>
          <div
            className="animate-fade-in transition-opacity duration-500 ease-in-out sm:hidden"
          >
            <div className="h-screen relative">
              <div className="relative">
                <img
                  src='/landing.jpeg'
                  alt="Pizza"
                  className="object-cover"
                />
                <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                  <h1 className="text-white text-3xl font-bold font-poppins">Ranč Ramarin</h1>
                </div>
                <div className='bg-primary h-10 w-10 fixed top-10 right-10 border rounded-3xl border-primary flex justify-center items-center shadow-lg'>
                  <FaShoppingCart></FaShoppingCart>
                </div>
                <div className='bg-primary h-10 w-10 fixed top-10 left-10 border rounded-3xl border-primary flex flex-col justify-center items-center shadow-lg'>
                  <div className='bg-black mb-1 h-0.5 w-5 rounded-xl'></div>
                  <div className='bg-black mb-1 h-0.5 w-5 rounded-3xl'></div>
                  <div className='bg-black h-0.5 w-5 rounded-xl'></div>

                </div>
              </div>
              <div className="bg-primary p-12  ">
                <HorizontalScroll />
                {listItems}
              </div>
            </div>
          </div>
        </div>
      <BottomSheet isOpen={isOpened} onClose={closeBottomSheet} food={selectedFood} />
    </div>

  );
}

export default App;
