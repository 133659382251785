import React from 'react';

const FoodCard = ({ name, price, imageUrl ,onClick }) => {
  return (
    <div className=" bg-gray-800 text-white rounded-lg shadow-lg overflow-hidden flex mb-2 max-h-24" onClick={()=> onClick}>
      <img className="w-32 h-32 object-cover" src={imageUrl} alt={name} />

      <div className="p-4">
        <h3 className="text-md font-semibold mb-2 font-nunito">{name}</h3>

          <span className="text-sm font-nunito">{price}€</span>
            <br></br>
         </div>
         
      </div>
  );
};

export default FoodCard;
