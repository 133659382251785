import React from "react";
import { Link } from "react-router-dom";

const Nopage = () => {
    return (
        <div className="flex flex-wrap justify-center md:justify-stretch mt-8 md:ml-8">
            <Link to="/" className="px-4 py-1 rounded-full border border-blue-500 shadow-md shadow-[rgba(59,130,246,0.25)] font-worksans uppercase text-2xl animate-pulse">error 404: not found</Link>
        </div>
    );
}

export default Nopage;