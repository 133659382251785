import React, { useState, useEffect } from 'react';

const SplashScreen = ({ onFinish }) => {
  const [isFadingOut, setIsFadingOut] = useState(false);

  useEffect(() => {
    // Start fading out the background after 2 seconds
    const timeout = setTimeout(() => {
      setIsFadingOut(true);
    }, 2000); // Background visible for 2 seconds before fading out

    // Trigger the onFinish callback after fade-out animation (1 second)
    if (isFadingOut) {
      const fadeOutTimeout = setTimeout(() => {
        onFinish();
      }, 1000); // Wait for fade-out transition to complete

      return () => clearTimeout(fadeOutTimeout);
    }

    return () => clearTimeout(timeout);
  }, [isFadingOut, onFinish]);

  return (
    <div
      className={`flex flex-col items-center justify-center h-screen transition-opacity bg-primary duration-1000 ease-in-out ${
        isFadingOut ? 'opacity-0' : 'opacity-100'
      }`}
    >
      {/* Text or Logo can be static here */}
      <div className="text-white text-4xl font-extrabold">
        Loading, please wait...
      </div>
    </div>
  );
};

export default SplashScreen;
