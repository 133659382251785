import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Nopage from "./pages/nopage";
import Admin from "./pages/admin";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home/>} />
        <Route path="/:menuId/:tableId" element={<Home/>} />
        
        <Route path="/admin" element={<Admin/>} />

        <Route path="*" element={<Nopage/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;