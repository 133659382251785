import axios from "axios";

const axiosClient = axios.create({
    baseURL: 'https://api.letmenu.com/v1',
});

const getMenus = () => axiosClient.get('/menus/672a5f3b1b78fa2f296f4f10/76ef121be7cfe228');

export default {
    getMenus
};
